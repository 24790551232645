<template>
  <div class="auth-wrapper auth-v2">
    <Loader v-if="loading"></Loader>
    <b-row class="auth-inner m-0">
      <img-logo />
      <b-col lg="6" class="recover-password">
        <h2 class="recover-password__title">Resete a sua senha</h2>
        <p class="recover-password__paragraph">
          Utilize uma senha forte com pelo menos 8 caracteres.
        </p>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm">
            <b-form-group label="Nova senha" label-for="password">
              <validation-provider
                #default="{ errors }"
                name="Senha"
                vid="Password"
                rules="required|password">
                <b-form-input
                  id="password"
                  v-model="login.password"
                  name="new-password"
                  type="password"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Senha Nova" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Repetir nova senha" label-for="c-password">
              <validation-provider
                #default="{ errors }"
                name="Confirma senha"
                rules="required|confirmed:Password">
                <b-form-input
                  id="c-password"
                  v-model="repeatPassword"
                  name="r-new-password"
                  type="password"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Repita Senha Nova" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              class="recover-password__button"
              type="submit"
              variant="primary"
              block>
              Resetar senha
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
      <background-card />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import BackgroundCard from './template/BackgroundCard.vue'
import ImgLogo from './template/ImgLogo.vue'
import toast from '@/mixins/toast'
import Loader from '@/components/Loader.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BackgroundCard,
    ImgLogo,
    Loader,
  },
  data() {
    return {
      emailAddress: '',
      required,
      email,
      reset: {
        emailAddress: null,
      },
      password: '',
      repeatPassword: '',
      status: '',
      userEmail: '',
      errors: '',
      key: '',
      getID: '',
      sendData: '',
      login: {
        userNameOrEmailAddress: null,
        password: null,
      },
      imgLogo: require('@/assets/images/logo/logo.png'),
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      loading: false
    }
  },

  mixins: [toast],

  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.recover()
        }
      })
    },
    async recover() {
      try {
        this.loading = true
        this.key = { c: this.$route.query.c }
        this.getID = await this.$http.post(
          '/api/services/app/Account/ResolveTenantId',
          this.key,
        )
        this.sendData = {
          password: this.login.password,
          c: this.key.c,
        }
        const response = await this.$http.post(
          '/api/services/app/Account/ResetPassword',
          this.sendData,
        )
        const success = response.data.result
        if (success) {
          setTimeout(() => {
            this.$router.push({ name: 'reset-password-seccess' })
            this.loading = false
          }, 1000)
        }
      } catch (error) {
        this.errors = error.message
        this.msgError('Prezado usuário, o link acessado está expirado. Solicite um novo link à administração da Divvy')
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import './sass/_recover-password.scss';
</style>
